import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';

import BackButton from '../../components/BackButton';
import { OfficeAPI } from '../../api/Client';
import { Navigate, useParams } from 'react-router-dom';
import AgencySalesGraph from '../Components/AgencySalesGraph/AgencySalesGraph';
import { getUserInfo } from '../../connect/auth';

function Office() {
  const { officeId } = useParams();
  const user_info = getUserInfo();

  if (!user_info.is_super_admin && !user_info.is_admin[officeId]) {
    console.log('Forbidden page, not enough rights');
    return <Navigate to="/dashboard" replace />;
  }
  const agencyId = user_info.offices_agencies[officeId];

  const [officeInfo, setOfficeInfo] = useState({});

  useEffect(() => {
    getOfficeInfo();
  }, []);

  function getOfficeInfo() {
    OfficeAPI.getInfo(officeId).then((r) => setOfficeInfo(r));
  }

  const { state, address, email, name } = officeInfo;

  return (
    <div className="animated fadeIn">
      <Row>
        <div className="col-md-12">
          <div className="card card-accent-primary">
            <div className="card-header">
              <h3>{name}</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <label className="text-right col-md-2">Область:</label>
                <p className="col-md-8">{state}</p>
              </div>
              <div className="row">
                <label className="text-right col-md-2">Адрес:</label>
                <p className="col-md-8">{address}</p>
              </div>
              <div className="row">
                <label className="text-right col-md-2">Email:</label>
                <p className="col-md-8">{email}</p>
              </div>
              {agencyId ? <AgencySalesGraph agencyId={agencyId} /> : null}
              <BackButton />
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
}

export default Office;
